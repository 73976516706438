<template>
  <div>
    <div class="listwork">
      <div class="listwork__top">
        <h4>Danh sách quan trắc môi trường</h4>
      </div>
      <div class="listwork__table__01">
        <vue-good-table
          :select-options="{ enabled: true }"
          :columns="columns"
          :rows="dataInvalid"
          style-class="vgt-table"
        >
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataInvalid.length }} quan trắc môi trường</p>
      </div>
      <div class="listwork__head__02">
        <div class="listwork__head__02__left">
          <h4>Danh sách quan trắc môi trường không hợp lệ</h4>
        </div>
        <div class="listwork__head__02__right">

          <b-button
            v-b-tooltip.hover.top="'Chỉnh sửa'"
            variant="primary"
            class="btn-icon"
            @click="onOffEdit()"
          >
            <feather-icon icon="EditIcon" />
          </b-button>

          <div class="listwork__head__02__right__icon">
            <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              v-b-tooltip.hover.top="'Kiểm tra'"
              variant="outline-primary"
              class="btn-icon"
              @click="CheckdataNoInvalid()"
            >
              <feather-icon icon="CheckCircleIcon" />
            </b-button>
          </div>
          <div />
        </div>

      </div>
      <div class="listwork__table__02">
        <vue-good-table
          :fixed-header="true"
          :columns="columns02"
          :rows="dataNoInvalid"
          :select-options="{ enabled: false}"
          style-class="vgt-table"
        >

          <template
            slot="table-row"
            slot-scope="props"
          >
            <!--======================= Thêm icon lỗi =================================== -->
            <span
              v-if="props.column.field == 'icon02'"
              v-b-tooltip.hover.right.html="props.row.messErr[0].message"
            >
              <feather-icon
                icon="AlertTriangleIcon"
                color="red"
              />
            </span>
            <!--======================= Color cho vị trí bị lỗi =================================== -->

            <span
              v-if="props.column.field == 'code' && editvalueErr === false "
              :class="{text_danger:props.row.messErr[0].location === 'Code'||!props.row.code,dataErr:!props.row.code,}"
            >
              {{ props.row.code }}
            </span>
            <span v-if="props.column.field == 'code' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Code'"
                v-model="props.row.code"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.code, 'code', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Code'"
              >{{ props.row.code }}</span>
            </span>

            <span
              v-if="props.column.field == 'startDate' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'StartDate'||!props.row.startDate,dataErr:!props.row.startDate,}"
            >
              {{ props.row.startDate }}
            </span>
            <span v-if="props.column.field == 'startDate' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'StartDate'"
                v-model="props.row.startDate"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.startDate, 'startDate', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'StartDate'"
              >{{ props.row.startDate }}</span>
            </span>

            <span
              v-if="props.column.field == 'bussinessId' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'BussinessId'||!props.row.bussinessId,dataErr:!props.row.bussinessId,}"
            >
              {{ props.row.bussinessId }}
            </span>
            <span v-if="props.column.field == 'bussinessId' && editvalueErr === true ">
              <v-select
                v-if="props.row.messErr[0].location === 'BussinessId'"
                v-model="props.row.bussinessId"
                :reduce="title => title.nameCode"
                label="name"
                :options="dataComboboxBusiness || []"
                @input="changeCellvalue(props.row.bussinessId, 'bussinessId', props.row)"
              >
                <template v-slot:no-options="{ searching }">
                  <template v-if="searching">
                    Không có kết quả.
                  </template>
                  <em
                    v-else
                  >Không có dữ liệu</em>
                </template>
              </v-select>
              <span
                v-if="props.row.messErr[0].location !== 'BussinessId'"
              >{{ props.row.bussinessId }}</span>
            </span>

            <span
              v-if="props.column.field == 'method' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Method'||!props.row.method,dataErr:!props.row.method,}"
            >
              {{ props.row.method }}
            </span>
            <span v-if="props.column.field == 'method' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Method'"
                v-model="props.row.method"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.method, 'method', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Method'"
              >{{ props.row.method }}</span>
            </span>

            <span
              v-if="props.column.field == 'method' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Method'||!props.row.method,dataErr:!props.row.method,}"
            >
              {{ props.row.method }}
            </span>
            <span v-if="props.column.field == 'method' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Method'"
                v-model="props.row.method"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.method, 'method', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Method'"
              >{{ props.row.method }}</span>
            </span>

            <span
              v-if="props.column.field == 'harmfulFactors' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'HarmfulFactors'||!props.row.harmfulFactors,dataErr:!props.row.harmfulFactors,}"
            >
              {{ props.row.harmfulFactors }}
            </span>
            <span v-if="props.column.field == 'harmfulFactors' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'HarmfulFactors'"
                v-model="props.row.harmfulFactors"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.harmfulFactors, 'harmfulFactors', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'HarmfulFactors'"
              >{{ props.row.harmfulFactors }}</span>
            </span>

            <span
              v-if="props.column.field == 'supplement' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Supplement'||!props.row.supplement,dataErr:!props.row.supplement,}"
            >
              {{ props.row.supplement }}
            </span>
            <span v-if="props.column.field == 'supplement' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Supplement'"
                v-model="props.row.supplement"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.supplement, 'supplement', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Supplement'"
              >{{ props.row.supplement }}</span>
            </span>

            <span
              v-if="props.column.field == 'reason' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'Reason'||!props.row.reason,dataErr:!props.row.reason,}"
            >
              {{ props.row.reason }}
            </span>
            <span v-if="props.column.field == 'reason' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'Reason'"
                v-model="props.row.reason"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.reason, 'reason', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'Reason'"
              >{{ props.row.reason }}</span>
            </span>

            <span
              v-if="props.column.field == 'machineClimate' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'MachineClimate'||!props.row.machineClimate,dataErr:!props.row.machineClimate,}"
            >
              {{ props.row.machineClimate }}
            </span>
            <span v-if="props.column.field == 'machineClimate' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'MachineClimate'"
                v-model="props.row.machineClimate"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.machineClimate, 'machineClimate', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'MachineClimate'"
              >{{ props.row.machineClimate }}</span>
            </span>

            <span
              v-if="props.column.field == 'machineLight' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'MachineLight'||!props.row.machineLight,dataErr:!props.row.machineLight,}"
            >
              {{ props.row.machineLight }}
            </span>
            <span v-if="props.column.field == 'machineLight' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'MachineLight'"
                v-model="props.row.machineLight"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.machineLight, 'machineLight', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'MachineLight'"
              >{{ props.row.machineLight }}</span>
            </span>

            <span
              v-if="props.column.field == 'machineNoise' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'MachineNoise'||!props.row.machineNoise,dataErr:!props.row.machineNoise,}"
            >
              {{ props.row.machineNoise }}
            </span>
            <span v-if="props.column.field == 'machineNoise' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'MachineNoise'"
                v-model="props.row.machineNoise"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.machineNoise, 'machineNoise', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'MachineNoise'"
              >{{ props.row.machineNoise }}</span>
            </span>

            <span
              v-if="props.column.field == 'machineDust' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'MachineDust'||!props.row.machineDust,dataErr:!props.row.machineDust,}"
            >
              {{ props.row.machineDust }}
            </span>
            <span v-if="props.column.field == 'machineDust' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'MachineDust'"
                v-model="props.row.machineDust"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.machineDust, 'machineDust', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'MachineDust'"
              >{{ props.row.machineDust }}</span>
            </span>

            <span
              v-if="props.column.field == 'machineRadioactive' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'MachineRadioactive'||!props.row.machineRadioactive,dataErr:!props.row.machineRadioactive,}"
            >
              {{ props.row.machineRadioactive }}
            </span>
            <span v-if="props.column.field == 'machineRadioactive' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'MachineRadioactive'"
                v-model="props.row.machineRadioactive"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.machineRadioactive, 'machineRadioactive', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'MachineRadioactive'"
              >{{ props.row.machineRadioactive }}</span>
            </span>

            <span
              v-if="props.column.field == 'machineElectronic' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'MachineElectronic'||!props.row.machineElectronic,dataErr:!props.row.machineElectronic,}"
            >
              {{ props.row.machineElectronic }}
            </span>
            <span v-if="props.column.field == 'machineElectronic' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'MachineElectronic'"
                v-model="props.row.machineElectronic"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.machineElectronic, 'machineElectronic', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'MachineElectronic'"
              >{{ props.row.machineElectronic }}</span>
            </span>

            <span
              v-if="props.column.field == 'machinePoison' && editvalueErr === false"
              :class="{text_danger:props.row.messErr[0].location === 'MachinePoison'||!props.row.machinePoison,dataErr:!props.row.machinePoison,}"
            >
              {{ props.row.machinePoison }}
            </span>
            <span v-if="props.column.field == 'machinePoison' && editvalueErr === true ">
              <input
                v-if="props.row.messErr[0].location === 'MachinePoison'"
                v-model="props.row.machinePoison"
                type="text"
                class="cssInput"
                @input="changeCellvalue(props.row.machinePoison, 'machinePoison', props.row)"
              >
              <span
                v-if="props.row.messErr[0].location !== 'MachinePoison'"
              >{{ props.row.machinePoison }}</span>
            </span>

          </template>
        </vue-good-table>
      </div>
      <div class="listwork__text__center">
        <p>Tổng cộng: {{ dataNoInvalid.length }} quan trắc môi trường</p>
      </div>

      <div class="listwork__button">
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            @click="addClassifyHealthy()"
          >
            Thêm quan trắc môi trường
          </b-button>
        </div>
        <div class="listwork__button__item">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            @click="close()"
          >
            Hủy bỏ
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BButton, VBTooltip } from 'bootstrap-vue'
import { mapState, mapActions, mapGetters } from 'vuex'
import Ripple from 'vue-ripple-directive'
import VSelect from 'vue-select'
import VueGoodTable from '@/components/table/VueGoodTable.vue'

export default {
  components: {
    VueGoodTable,
    BButton,
    VSelect,

  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      model: {},
      dataInvalid: [],
      dataNoInvalid: [],
      editvalueErr: false, // bật tắt chỉnh sửa
      columns: [
        {
          label: 'Mã quan trắc',
          field: 'code',
        },
        {
          label: 'Ngày thực hiện',
          field: 'startDate',
        },
        {
          label: 'Doanh nghiệp quan trắc',
          field: 'bussinessId',
        },
        {
          label: 'Phương pháp',
          field: 'method',
        },
        {
          label: 'Quan trắc các yếu tố có hại',
          field: 'harmfulFactors',
        },
        {
          label: 'Đề nghị bổ sung việc quan trắc các yếu tố có hại',
          field: 'supplement',
        },
        {
          label: 'Lý do đề xuất',
          field: 'reason',
        },
        {
          label: 'Đo khí hậu bằng máy',
          field: 'machineClimate',
        },
        {
          label: 'Đo ánh sáng bằng máy',
          field: 'machineLight',
        },
        {
          label: 'Đo tiếng ồn bằng máy',
          field: 'machineNoise',
        },
        {
          label: 'Đo bụi bằng máy',
          field: 'machineDust',
        },
        {
          label: 'Đo phóng xạ bằng máy',
          field: 'machineRadioactive',
        },
        {
          label: 'Đo điện trường bằng máy',
          field: 'machineElectronic',
        },
        {
          label: 'Đo hơi khí độc bằng máy',
          field: 'machinePoison',
        },
      ],
      columns02: [
        {
          label: '',
          field: 'icon02',
        },
        {
          label: 'Mã quan trắc',
          field: 'code',
        },
        {
          label: 'Ngày thực hiện',
          field: 'startDate',
        },
        {
          label: 'Doanh nghiệp quan trắc',
          field: 'bussinessId',
        },
        {
          label: 'Phương pháp',
          field: 'method',
        },
        {
          label: 'Quan trắc các yếu tố có hại',
          field: 'harmfulFactors',
        },
        {
          label: 'Đề nghị bổ sung việc quan trắc các yếu tố có hại',
          field: 'supplement',
        },
        {
          label: 'Lý do đề xuất',
          field: 'reason',
        },
        {
          label: 'Đo khí hậu bằng máy',
          field: 'machineClimate',
        },
        {
          label: 'Đo ánh sáng bằng máy',
          field: 'machineLight',
        },
        {
          label: 'Đo tiếng ồn bằng máy',
          field: 'machineNoise',
        },
        {
          label: 'Đo bụi bằng máy',
          field: 'machineDust',
        },
        {
          label: 'Đo phóng xạ bằng máy',
          field: 'machineRadioactive',
        },
        {
          label: 'Đo điện trường bằng máy',
          field: 'machineElectronic',
        },
        {
          label: 'Đo hơi khí độc bằng máy',
          field: 'machinePoison',
        },
      ],
      dataComboboxBusiness: [],
    }
  },
  computed: {
    ...mapGetters('environmentalMonitoring', ['validWork', 'validNoWork', 'dataTooltip']),
    ...mapGetters('performTraining', ['dataAllBusiness']),
  },
  created() {
    this.dataInvalid = this.validWork
    this.dataNoInvalid = this.validNoWork
    this.checkErrorUser(this.dataTooltip)
  },
  methods: {
    ...mapActions('environmentalMonitoring', ['getApiExcel']),
    ...mapActions('performTraining', ['fetchDataComboboxAllBusiness']),

    // BẬT tắt chỉnh sửa
    async onOffEdit() {
      if (this.dataAllBusiness.length === 0) {
        await this.fetchDataComboboxAllBusiness()
      }
      this.dataComboboxBusiness = this.dataAllBusiness.map(element => ({
        ...element,
        nameCode: `${element.code} - ${element.name}`,
      }))
      this.editvalueErr = !this.editvalueErr
    },
    // thay đổi dữ liệu trên bảng
    changeCellvalue(event, field, rowData) {
      this.dataNoInvalid[rowData.originalIndex][field] = event
    },
    // nút check
    async CheckdataNoInvalid() {
      const model = {
        listExcel: this.dataNoInvalid,
        isValidate: true,
      }
      const valuedata = await this.getApiExcel(model)
      const dataInvalid01 = valuedata.data.data.filter(x => x.isSuccess === true)
      this.dataInvalid = this.dataInvalid.concat(dataInvalid01)
      const dataNoInvalid01 = valuedata.data.data.filter(x => x.isSuccess === false)
      if (dataNoInvalid01.length > 0) {
        this.dataNoInvalid = dataNoInvalid01
        this.checkErrorUser(this.dataNoInvalid)
      } else {
        this.dataNoInvalid = []
      }
    },
    // nút hủy bỏ
    close() {
      this.dataInvalid = []
      this.dataNoInvalid = []
      this.$router.push({ name: 'environmental-monitoring' })
    },
    // nút thêm
    // eslint-disable-next-line consistent-return
    async addClassifyHealthy() {
      const model = {
        listExcel: this.dataInvalid,
        isValidate: false,
      }
      this.getApiExcel(model).then(res => {
        if (res.status === 200) {
          this.$root.$bvToast.toast('Thêm thành công', {
            title: 'Thông báo',
            variant: 'success',
            toaster: this.$toastPosition,
            solid: true,
          })
          this.dataInvalid = []
          if (this.dataNoInvalid.length === 0) {
            this.$router.push({ name: 'environmental-monitoring' })
          }
        } else {
          this.$root.$bvToast.toast('Thêm không thành công', {
            title: 'Thông báo',
            variant: 'danger',
            toaster: this.$toastPosition,
            solid: true,
          })
        }
      })
    },
    checkErrorUser(data) {
      // kiểm tra lỗi
      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          const element = data[i]
          if (element.isSuccess === false) {
            /* eslint no-loop-func: "error" */
            let messageError = ''
            if (element.messErr) {
              element.messErr.forEach(err => {
                messageError += `${this.$t(`${err.message}`)} <br> `
              })
            }
            data[i].messageError = messageError
          } else { element.isSelected = true }
        }
      }
    },

  },
}
</script>

<style>
.listwork{
background: white;
box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.06);
border-radius: 6px;

}

.listwork__top{
padding:20px;}
.listwork__top h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;

color: #181F28
}
.listwork__text__center p{
font-family: Montserrat;
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
text-align: right;
margin-top:20px;
padding-right:20px;

}
.listwork__head__02{
display: flex;
align-items: center;
justify-content: space-between;
padding:20px;
}
.listwork__head__02__left h4{
font-family: Montserrat;
font-style: normal;
font-weight: 500;
font-size: 18px;
line-height: 22px;
color: #000000;
}
.listwork__head__02__right{
display: flex;
align-items: center;}
.listwork__head__02__right__icon{
margin-left: 20px;}

.listwork__button{
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-top:40px;
  padding-right: 20px;
  padding-bottom: 20px;
}
.listwork__button__item{
  margin-left:20px
}
.table-listwork{
    width: 100%;
}
.table-listwork thead tr th{
  background-color: rgba(34, 127, 244, 0.12);
padding-left:10px ;
}

.table-listwork thead tr{
  height: 39px;
  background-color: transparent;
}
.table-listwork  tbody tr{
  height: 58px;
  border-bottom: 1px solid #CBCDD2;

}
.table-listwork  tbody tr th{
 padding-left:10px ;
}
.table-listwork  tbody tr td{
 padding-left:10px ;
}
.text_danger{
  color:red
}
.cssInput{
  outline: none;
  border: 0px;
  border-bottom: 0.2px solid gray;
}
.style-code123{
  padding-right: 20px ;
}
.dataErr::before{
  content: 'Không được để trống';
  color:red,
}
</style>
